import { useState } from "react";
import GalleryLightbox from "./gallery/GalleryLightbox";
import ProductGallery from "./products/ProductGallery";

const productsDetails = [
  {
    src: "/assets/images/products/villa-elevator.png",
    alt: "villa elevator",
    title: "Villa Elevator",
    desc: "HITECH villa elevator adheres to the design idea of enviromental protection and energy-saving and merges with the latest elevator technology nowdays in order to ensure the safe and reliable products $ the comfortable travel.",
  },
  {
    src: "/assets/images/products/panoramic-elevator.png",
    alt: "panoramic elevator",
    title: "Panoramic Elevator",
    desc: "HITECH observation elevator adhere to design principle of innovativeness and fashion to meet various demands of hotels and supermarket.according their building styles.",
  },
  {
    src: "/assets/images/products/dumbwaiter-elevator.png",
    alt: "dumbwaiter elevator",
    title: "Dumbwaiter Elevator",
    desc: "Dumbwaiter elevator is the smallest member of the elevator family. According to different use occasions, also known as kitchen elevator, meal ladder or multi-use miscellaneous ladder.",
  },
  {
    src: "/assets/images/products/freight-elevator.png",
    alt: "Freight elevator",
    title: "Freight Elevator",
    desc: "HITECH freight elevator combined with elevator engineering technology and other top technology, strong drive system, high strength of the mechanical structure design, not only can accommodate heavy goods but also can operate in variety of harsh environments.",
  },
  {
    src: "/assets/images/products/escalator-moving-walk.png",
    alt: "Escalator & Moving Walk",
    title: "Escalator & Moving Walk",
    desc: "HITECH escalator products are produced according to the current international standard and Chinese standard. It fully applies the novel materials and advanced technology both at home and aboard to design and manufacture the lifts.",
  },
];

const Products = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div id="products" className="m-globals">
      <h1 className="font-bold text-[40px] text-[#909090] text-center mb-10">
        Product Catalogue
      </h1>
      <ProductGallery
        products={productsDetails}
        handleClick={(index) => {
          setCurrentImageIndex(index);
          setIsOpen(true);
        }}
      />
      <GalleryLightbox
        images={productsDetails}
        isOpen={isOpen}
        currentImageIndex={currentImageIndex}
        setCurrentImageIndex={setCurrentImageIndex}
        handleClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default Products;
