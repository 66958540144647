const Customer = () => {
  const customerLists = [
    "fotexco.png",
    "rsumufid.webp",
    "poltekkes1.png",
    "yenprima.jpg",
    "pagisore.png",
    "gunze.png",
    "sumbermas.webp",
    "mjs.png",
    "dprdsamarinda.png",
    "suryamakmur.png",
    "smktazakka.png",
    "KKP Ind.png",
  ];
  return (
    <div id="customer" className="m-globals mb-20">
      <h1 className="text-center text-[#909090] text-[40px] font-bold">
        Our Customers
      </h1>
      <div className="grid sm:grid-cols-4 grid-cols-2 gap-x-5 gap-y-10 items-center scale-90">
        {customerLists.map((image, i) => (
          <img
            key={i}
            src={`/assets/images/${image}`}
            alt={image}
            className="grayscale hover:grayscale-0 duration-150 transition-all object-contain aspect-[3/2] mx-auto max-h-48"
          />
        ))}
      </div>
    </div>
  );
};

export default Customer;
