import Profile from "./components/Profile";
import Hero from "./components/Hero";
import Customer from "./components/Customer";
import Partner from "./components/Partner";
import Team from "./components/Team";
import Gallery from "./components/Gallery";
import Contact from "./components/Contact";
import Products from "./components/Product";

const App = () => {
  return (
    <>
      <Hero />
      <Profile />
      <Customer />
      <Partner />
      <Team />
      <Gallery />
      <Products />
      <Contact />
    </>
  );
};

export default App;
