const ImageGallery = ({ images, handleClick }) => {
  return (
    <div className="grid sm:grid-cols-4 grid-cols-1 gap-5">
      {images.map(({ src, alt, project }, index) => (
        <div className="max-h-[480px] relative" key={index}>
          <div
            className="h-full cursor-pointer overflow-hidden rounded-lg"
            onClick={() => handleClick(index)}
          >
            <img
              src={src}
              alt={alt}
              className="mx-auto h-full rounded-lg w-full object-cover transition-all duration-300 hover:rotate-6 hover:scale-125"
            />
          </div>
          <div className="absolute top-2/3 bottom-0 left-0 right-0 bg-gradient-to-b from-transparent to-black/90 z-10 rounded-b-lg">
            <span> </span>
          </div>
          <div className="absolute bottom-3 left-3 right-3 text-white z-20">
            <h1 className="font-bold">{alt}</h1>
            <h1 className="font-thin">{project}</h1>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;
