import { useState } from "react";
import GalleryLightbox from "./gallery/GalleryLightbox";
import ImageGallery from "./gallery/ImageGallery";

const images = [
  // {
  //   src: "/assets/images/gallery/01.jpeg",
  //   alt: "Beauty Clinic Kelapa Gading",
  //   project: "Project HiTech Elevator 450KG 4Floor",
  // },
  {
    src: "/assets/images/gallery/02.jpeg",
    alt: "Beauty Clinic Kelapa Gading",
    project: "Project HiTech Elevator 450KG 4Floor",
  },
  {
    src: "/assets/images/gallery/03.jpeg",
    alt: "Beauty Clinic Kelapa Gading",
    project: "Project HiTech Elevator 450KG 4Floor",
  },
  {
    src: "/assets/images/gallery/04.jpeg",
    alt: "Executive Kost Jogja",
    project: "Project HiTech Elevator 630KG 4Floor",
  },
  {
    src: "/assets/images/gallery/05.jpeg",
    alt: "Executive Kost Jogja",
    project: "Project HiTech Elevator 630KG 4Floor",
  },
  {
    src: "/assets/images/gallery/06.jpeg",
    alt: "Executive Kost Jogja",
    project: "Project HiTech Elevator 630KG 4Floor",
  },
  {
    src: "/assets/images/gallery/07.jpeg",
    alt: "Executive Kost Jogja",
    project: "Project HiTech Elevator 630KG 4Floor",
  },
  // {
  //   src: "/assets/images/gallery/10.jpeg",
  //   alt: "Executive Kost Jogja",
  //   project: "Project HiTech Elevator 630KG 4Floor",
  // },
  {
    src: "/assets/images/gallery/08.jpeg",
    alt: "Taman Teduh Cafe Semarang",
    project: "Project Dumbwaiter",
  },
  {
    src: "/assets/images/gallery/09.jpeg",
    alt: "Taman Teduh Cafe Semarang",
    project: "Project Dumbwaiter",
  },
];

const Gallery = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div id="work" className="m-globals mb-20">
      <h1 className="font-bold text-[40px] text-[#909090] text-center mb-10">
        Our Work
      </h1>
      <ImageGallery
        images={images}
        handleClick={(index) => {
          setCurrentImageIndex(index);
          setIsOpen(true);
        }}
      />
      <GalleryLightbox
        images={images}
        isOpen={isOpen}
        currentImageIndex={currentImageIndex}
        setCurrentImageIndex={setCurrentImageIndex}
        handleClose={() => setIsOpen(false)}
      />
    </div>
  );
};
export default Gallery;
