const Partner = () => {
  return (
    <div
      id="partner"
      className="relative flex items-center h-[850px] m-globals mb-20"
    >
      <div className="bg-[#F1F5FF] h-full absolute top-0 left-0 right-0 -z-10">
        <div className="flex justify-end items-center h-full">
          <img
            src="/assets/images/partner-bg.jpg"
            alt="partner bg"
            className="w-7/12 h-4/6 object-cover sm:opacity-100 opacity-50"
          />
        </div>
      </div>
      <div className="sm:w-2/3 w-full">
        <h1 className="text-[40px] font-bold sm:text-[#909090]">Our Partner</h1>
        <div className="sm:bg-white bg-white/90 grid sm:grid-cols-2 grid-cols-1 p-5 items-center">
          <img
            src="/assets/images/hitech.png"
            alt="hitech logo"
            className="mx-auto"
          />
          <p className="block font-light">
            Suzhou Hitech Elevator CO.,LTD has over 10 years' experience in the
            design and manufacturing of complete elevators, escalators and
            related spare parts. So far, out product have been using in more
            than 80 countries including the republic of Georgia, Indonesia,
            Middle East, India, Singapore, USA , and South America.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Partner;
