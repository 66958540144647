import { useState } from "react";
import {
  FaWhatsapp,
  FaFacebookSquare,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";

const Contact = () => {
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  const chatHandler = () => {
    const phone = "6285894148387";
    const text = `*Subject :* ${subject}\n*Description :* ${description}`;
    const url = `https://api.whatsapp.com/send/?phone=${phone}&text=${encodeURI(
      text
    )}`;
    return url.trim();
  };

  return (
    <div id="contact" className="m-globals">
      <h1 className="font-bold text-[40px] text-[#909090] mb-10">Contact Us</h1>
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-y-5 gap-x-10">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1983.1494281684124!2d106.975676!3d-6.224269!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e698c6e6a3df22f%3A0xfb86cc30e6c579cc!2sJl.%20Lap.%20Bola%20No.83%2C%20RT.003%2FRW.015%2C%20Kranji%2C%20Kec.%20Bekasi%20Bar.%2C%20Kota%20Bks%2C%20Jawa%20Barat%2017135%2C%20Indonesia!5e0!3m2!1sen!2sus!4v1684066240361!5m2!1sen!2sus"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Location Maps"
          height="480"
          className="border-0 rounded-lg shadow-lg w-full"
        ></iframe>
        <div>
          <h1 className="text-[20px] font-bold">Location</h1>
          <p className="font-light mb-5">
            Jl. Lapangan Bola No,83 RT 003 RW 015 Kranji Bekasi Barat Kota
            Bekasi, Jawa Barat 17135
          </p>
          <h1 className="text-[20px] font-bold mb-2">Chat with Us</h1>
          <div className="mb-5">
            <div className="mb-5">
              <p className="font-light">Subject :</p>
              <input
                type="text"
                className="border border-[#467EFF] bg-[#F6F6F6] placeholder:font-light placeholder:italic rounded p-2 w-full sm:w-2/3 outline-none focus:shadow-lg transition-all duration-150"
                placeholder="Ask for installation..."
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="mb-5">
              <p className="font-light">Description :</p>
              <textarea
                type="text"
                className="border border-[#467EFF] bg-[#F6F6F6] placeholder:font-light placeholder:italic rounded p-2 w-full sm:w-5/6 outline-none focus:shadow-lg transition-all duration-150"
                placeholder="Good day sir, I would like to know more about your company..."
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <a
              href={chatHandler()}
              target="_blank"
              rel="noreferrer"
              className="sm:w-40 w-full py-2 px-2 bg-[#D1DFFF] rounded-md active:scale-95 transition-all duration-100 flex items-center justify-center gap-2"
            >
              <FaWhatsapp className="text-[16px] text-[#467EFF]" />
              <span className="font-medium text-[16px] text-[#467EFF]">
                Send
              </span>
            </a>
          </div>
          <div className="flex items-center sm:justify-start justify-center">
            <h1 className="text-2xl font-bold mr-5">Follow Us</h1>
            <div className="flex gap-4">
              <FaFacebookSquare
                className="text-3xl cursor-pointer hover:text-[#467EFF] duration-150 transition-all"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/lift.escalator17/",
                    "_blank"
                  )
                }
              />
              <FaInstagram
                className="text-3xl cursor-pointer hover:text-[#467EFF] duration-150 transition-all"
                onClick={() =>
                  window.open("https://www.instagram.com/", "_blank")
                }
              />
              <FaTwitter
                className="text-3xl cursor-pointer hover:text-[#467EFF] duration-150 transition-all"
                onClick={() =>
                  window.open("https://www.twitter.com/", "_blank")
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
