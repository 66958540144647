import { IoMdArrowDroprightCircle } from "react-icons/io";
const Profile = () => {
  return (
    <div
      id="profile"
      className="grid sm:grid-cols-2 grid-cols-1 gap-5 m-globals items-center sm:mb-28"
    >
      <img
        src="/assets/images/worker-discussion.png"
        alt="worker-discussion"
        className="sm:h-[50vh] object-contain z-10 sm:mb-0 mb-5"
      />
      <div>
        <h1 className="font-bold text-[#909090] text-[40px]">About Us</h1>
        <p className="font-light text-lg text-[#4d4d4d] w-full mb-5">
          <strong>Sakha Pratama Mandiri</strong> merupakan perusahaan yang
          bergerak di bidang elevator dan eskalator yang mempunyai kompeten di
          bidangnya untuk dapat memberikan pelayanan terbaik.
        </p>
        <div className="flex gap-x-4 items-center">
          <IoMdArrowDroprightCircle size="30px" />
          <h1 className="font-medium text-2xl">Visi</h1>
        </div>
        <p className="ps-12 text-lg font-light text-[#4d4d4d] mb-3 w-full">
          Terus maju siap berkompetisi seiring perkembangan era modernisasi.
          Tumbuh dan berkembang bersama dengan kepuasan konsumen yang lebih
          utama.
        </p>
        <div className="flex gap-x-4 items-center">
          <IoMdArrowDroprightCircle size="30px" />
          <h1 className="font-medium text-2xl">Misi</h1>
        </div>
        <p className="ps-12 text-lg font-light text-[#4d4d4d] mb-3 w-full">
          Menjadi salah satu badan usaha yang terbaik dalam bidang elevator dan
          eskalator.
        </p>
        <h1 className="font-bold sm:text-left text-center sm:mt-0 mt-8 sm:text-[20px]">
          Get Our Latest Company Profile{" "}
          <a
            target="_blank"
            className="text-[#467EFF] font-light underline"
            href="/assets/docs/SPM Company Profile.pdf"
          >
            Download
          </a>
        </h1>
      </div>
    </div>
  );
};

export default Profile;
