import { FaDownload } from "react-icons/fa";

const ProductGallery = ({ products, handleClick }) => {
  return (
    <div className="grid sm:grid-cols-3 grid-cols-1 gap-5">
      {products.map((product, index) => (
        <div key={index} className="">
          <div
            className="cursor-pointer overflow-hidden rounded-lg"
            onClick={() => handleClick(index)}
          >
            <img
              src={product.src}
              alt={product.alt}
              className="aspect-square object-cover mx-auto rounded-lg transition-all duration-300 hover:rotate-6 hover:scale-125 "
            />
          </div>
          <h1 className="font-bold text-xl">{product.title}</h1>
          <p className="font-light">{product.desc}</p>
        </div>
      ))}
      <div className="place-self-center sm:-mt-52 w-full text-center">
        <p className="mb-2 font-light">
          Get more products and sparepart details by downloading our product
          catalogue
        </p>
        <a
          href="/assets/docs/SPM Catalogue.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <button className="flex justify-center gap-2 items-center w-56 px-2 py-3 text-[#467EFF] bg-[#D1DFFF] rounded-md active:scale-95 transition-all duration-100 mx-auto">
            <FaDownload />
            <h1>Products Catalogue</h1>
          </button>
        </a>
      </div>
    </div>
  );
};

export default ProductGallery;
