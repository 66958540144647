import Lightbox from "react-spring-lightbox";

const GalleryLightbox = ({
  isOpen,
  images,
  currentImageIndex,
  setCurrentImageIndex,
  handleClose,
}) => {
  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < images.length &&
    setCurrentImageIndex(currentImageIndex + 1);

  return (
    <Lightbox
      isOpen={isOpen}
      onPrev={gotoPrevious}
      onNext={gotoNext}
      images={images}
      currentIndex={currentImageIndex}
      /* Add your own UI */
      // renderHeader={() => (<CustomHeader />)}
      // renderFooter={() => (<CustomFooter />)}
      // renderPrevButton={() => (<FaArrowCircleLeft size='3rem' className="mx-5 text-slate-800" />)}
      // renderNextButton={() => (<FaArrowCircleRight size='3rem' className="mx-5 text-slate-800" />)}
    //   renderImageOverlay={() => <div></div>}
      //* Add styling */
      className="bg-white/90"
      //   style={{ background: "rgba(0,0,0,0.5)" }}

      /* Handle closing */
      onClose={handleClose}

      /* Use single or double click to zoom */
      // singleClickToZoom

      /* react-spring config for open/close animation */
      // pageTransitionConfig={{
      //   from: { transform: "scale(0.75)", opacity: 0 },
      //   enter: { transform: "scale(1)", opacity: 1 },
      //   leave: { transform: "scale(0.75)", opacity: 0 },
      //   config: { mass: 1, tension: 320, friction: 32 }
      // }}
    />
  );
};

export default GalleryLightbox;
