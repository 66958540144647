import { useEffect, useState } from "react";
import "../styles/navbar.css";
import { FaBars } from "react-icons/fa";
import useMediaQuery from "../lib/useMediaQuery";
const NavBar = () => {
  const [isShow, setIsShow] = useState(false);
  const mobileScreen = useMediaQuery("(max-width: 600px)");
  const showMenuHandler = () => setIsShow(!isShow);
  const menus = [
    "home",
    "profile",
    "customer",
    "team",
    "work",
    "products",
    "contact",
  ];
  useEffect(() => {
    setIsShow(mobileScreen ? false : true);
  }, [mobileScreen]);
  return (
    <nav className="flex flex-wrap gap-2 justify-between items-center fixed top-0 left-0 right-0 sm:px-32 px-5 py-5 z-20 bg-[#F1F5Ff]/80">
      <div className="flex gap-2 items-center flex-grow">
        <img
          src="/assets/images/sakhapratama.png"
          alt="sakha pratama mandiri logo"
          className="max-w-[2.5rem]"
        />
        <h1 className="font-bold sm:text-[32px] text-xl sm:mb-0">
          Sakha Pratama Mandiri
        </h1>
      </div>
      <FaBars
        onClick={showMenuHandler}
        className="sm:hidden text-right text-2xl cursor-pointer hover:text-black/50 active:text-black/90"
      />
      {isShow && (
        <div className="sm:flex sm:flex-row flex-col sm:w-auto w-full items-center gap-[24px]">
          {menus.map((menu, index) => (
            <a
              href={"#" + menu}
              key={index}
              className="block text-center sm:mb-0 mb-5 active font-bold text-[16px] text-slate-400"
            >
              {menu}
            </a>
          ))}
        </div>
      )}
    </nav>
  );
};

export default NavBar;
