import NavBar from "./NavBar";

const Hero = () => {
  return (
    <div id="home" className="m-globals h-[848px] overflow-hidden mb-20">
      <div className="absolute top-0 left-0 right-0 bg-[#F1F5FF] min-h-[848px]  -z-10"></div>
      <NavBar />
      <div className="grid sm:grid-cols-2 grid-cols-1 h-5/6 items-center mt-14">
        <div className="sm:mb-0 mb-28">
          <h1 className="font-bold uppercase sm:text-6xl text-4xl text-[#80A6FF] sm:mb-5 md:mb-2">
            lift terbaik
          </h1>
          <h1 className="font-bold uppercase sm:text-6xl  text-4xl sm:mb-5 mb-2 text-[#80A6FF]">
            harga terbaik
          </h1>
          <h2 className="font-light sm:text-[16px] text-[#898989] sm:w-1/2 sm:mb-16 mb-6">
            Pengadaan dan pemasangan elevator, eskalator, lift barang dan
            dumbwaiters semua sektor bangunan
          </h2>
          <a href="#contact" className="font-medium text-[16px] text-[#467EFF]">
            <button className="w-56 py-2 px-2 bg-[#D1DFFF] rounded-md active:scale-95 transition-all duration-100">
              Contact Us
            </button>
          </a>
        </div>
        <div className="relative flex justify-center">
          <div className="absolute top-[-6rem] z-10">
            <img
              src="/assets/images/landing-page-image.png"
              alt="construction worker"
              className="scale-110"
            />
          </div>
          <div>
            <img
              src="/assets/images/blob.png"
              alt="blob shape"
              className="scale-110"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
