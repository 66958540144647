import { FaWhatsapp, FaInstagram } from "react-icons/fa";
import { MdOutlineAttachEmail } from "react-icons/md";
const Team = () => {
  const teams = [
    {
      name: "Mr. Ipan",
      phone: "6281932561986",
      email: "sakhaprtmandiri@gmail.com",
      ig: "indolift",
      position: "Director",
      img: "ipan.png",
    },
    {
      name: "Aenu Rofik",
      phone: "6285894148387",
      email: "sakhaprtmandiri@gmail.com",
      ig: "indolift",
      position: "General Manager",
      img: "aenu-rofik.png",
    },
    {
      name: "Gatot Hadi I.",
      phone: "6285250187851",
      email: "sakhaprtmandiri@gmail.com",
      ig: "indolift",
      position: "Marketing Executive",
      img: "gatot.png",
    },
    {
      name: "Dedi Prabantoro",
      phone: "6281222225335",
      email: "sakhaprtmandiri@gmail.com",
      ig: "indolift",
      position: "Marketing Executive",
      img: "dedi-prabantoro.png",
    },
  ];
  return (
    <div id="team" className="m-globals mb-20">
      <h1 className="text-[40px] text-[#909090] font-bold text-center mb-10">
        Our Team
      </h1>
      <div className="grid 2xl:grid-cols-4 xl:grid-cols-2 grid-cols-1 gap-y-5 items-center">
        {teams.map((team, index) => (
          <div key={index} className="flex items-center flex-col">
            <img
              src={`/assets/images/team/${team.img}`}
              alt={team.img}
              className="mx-auto hover:drop-shadow-2xl duration-200 transition-all"
            />
            <h1 className="text-[32px] font-bold">{team.name}</h1>
            <h1 className="text-[20px] font-medium text-[#909090] mb-2">
              {team.position}
            </h1>
            <div className="flex justify-center gap-4">
              <a
                href={`https://wa.me/${team.phone}`}
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp
                  size="2rem"
                  className="cursor-pointer hover:text-slate-500 transition-all duration-150"
                />
              </a>
              <a
                href={`https://www.instagram.com/${team.ig}`}
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  size="2rem"
                  className="cursor-pointer hover:text-slate-500 transition-all duration-150"
                />
              </a>
              <a href={`mailto:${team.email}`} target="_blank" rel="noreferrer">
                <MdOutlineAttachEmail
                  size="2rem"
                  className="cursor-pointer hover:text-slate-500 transition-all duration-150"
                />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Team;
